import React from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckbox, NamedLink } from '../../components';
import * as validators from '../../util/validators';
import { composeValidators,required } from '../../util/validators';

// Create this file using EditListingPricingForm.module.css
// as a template.
import css from './EditListingTermsOfServiceForm.module.css';


export const EditListingTermsOfServiceFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        values,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
      } = formRenderProps;
      const checkRequiredMessage = "you need to agree to terms and conditions";

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTermsOfServiceForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      console.log(values.TermsAndService, 'values.TermsAndService')
      
      const submitDisabled = invalid || disabled || submitInProgress || !values.TermsOfService;
      

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          {/* <FieldTextInput
            className={css.input}
            id="serviceName"
            name="serviceDetails"
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingTermsOfServiceForm.serviceDetails',
            })}
          /> */}
          <Link to="/p/general-pet-host-terms" className={css.legalLink}>
            <FormattedMessage id="EditListingTermsOfServiceForm.petHostTerms" />
          </Link>
          <FieldCheckbox
            className={css.features}
            id="TermsOfService"
            name="TermsOfService"
            label={intl.formatMessage({
              id: 'EditListingTermsOfServiceForm.termsOfServiceDetails',
            })}
            validate={composeValidators(required(checkRequiredMessage))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingTermsOfServiceFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingTermsOfServiceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(
  EditListingTermsOfServiceFormComponent
);